<template>
<div class="">
  <div class="px-3">
    <div class="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Send OTP to email:</span>
        <b-form-checkbox
         style="opacity: 0.3"
        :id="`SendOTPtoEmail-${Date.now()}`"
           v-model="block.isEmailOTP"
           disabled
                  ></b-form-checkbox>
      </label>
      <div class="">
        <i style="font-size: 13px" class="text-danger">
          Note : It is crucial to have an email Input in your lead page for this
          feature to work.</i
        >
      </div>
    </div>
    
  </div>
  <div class="otp-input-section">
        <div v-b-toggle.otp-input-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">OTP Input Style</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="otp-input-collapse" class="mt-2">
         <div class="px-3">
          <div class="">
            <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Back Button Text:</span>
       
      </label>
      <input type="text"  placeholder="Back Button Text" v-model="block.goBackBtnText" class="w-100 editor-input-control" id="">
          </div>
          <div class="mt-2">
            <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Resend Button Text:</span>
       
      </label>
      <input type="text"  placeholder="resendOtpText Button Text" v-model="block.resendOtpText" class="w-100 editor-input-control" id="">
          </div>
         </div>
          <hr>
          <div class="px-3">
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number" v-model="block.style.authInputWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number"  v-model="block.style.authInputHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
  
</div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.strokeColor" :opacity.sync="block.style.strokeOpacity"  :label="'Stroke:'"></SelectColor>
  <div class="d-flex justify-content-center mt-2" style="gap:4px">
    <div class="d-flex text-align-section" style="gap:4px">
      <label for="" :class="{
                'active-editor-align': block.style.borderType === 'border',
              }"
              @click="block.style.borderType = 'border'"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.24167 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.24167 16.75 2.5 15.8333 2.5ZM15.8333 4.16667V15.8333H4.16667V4.16667H15.8333Z" fill="currentColor"/>
</svg>
</label>
<label for=""    :class="{
                'active-editor-align': block.style.borderType === 'border-bottom',
              }"
              @click="block.style.borderType = 'border-bottom'" ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5455 8.72727H13.091V7.27273H14.5455V8.72727ZM2.90914 8.72727H1.45459V7.27273H2.90914V8.72727ZM8.72732 2.90909H7.27277V1.45454H8.72732V2.90909ZM11.6364 2.90909H10.1819V1.45454H11.6364V2.90909ZM14.5455 5.81818H13.091V4.36364H14.5455V5.81818ZM14.5455 2.90909H13.091V1.45454H14.5455V2.90909ZM14.5455 11.6364H13.091V10.1818H14.5455V11.6364ZM2.90914 11.6364H1.45459V10.1818H2.90914V11.6364ZM5.81823 2.90909H4.36368V1.45454H5.81823V2.90909ZM2.90914 2.90909H1.45459V1.45454H2.90914V2.90909ZM2.90914 5.81818H1.45459V4.36364H2.90914V5.81818ZM14.5455 14.5455H1.45459V13.0909H14.5455V14.5455Z" fill="currentColor"/>
</svg>
</label>
    </div>
  <Stroke :borderSize.sync="block.style.borderSize"
  :borderRadius.sync="block.style.borderRadius"  ></Stroke>
</div> 
</div>
<hr>
<div class="px-3">
  <label  for="button-text" class="editor-label">Button Position:</label>
    <div class="d-flex text-align-section">
      <label title="Start" for="left" @click="block.style.authInputPosition='left'" :class="block.style.authInputPosition=='left'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label title="Center" for="center"  @click="block.style.authInputPosition='center'" :class="block.style.authInputPosition=='center'?'active-editor-align':''"  class="editor-text-center-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label for="end" title="End" @click="block.style.authInputPosition='end'" :class="block.style.authInputPosition=='end'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
       
    </div>
</div>
<hr>

        </b-collapse>
          </div>
          <div class="otp-action-section">
        <div v-b-toggle.otp-heading-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">OTP Heading Style</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="otp-heading-collapse" class="mt-2">
         <div class="px-3">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Show Text:</span>
        <span class="d-flex align-items-center gap">{{!block.showLabel?'Show':'Hide'}}  <b-form-checkbox
                       :id="`ShowLabel-${Date.now()}`"
                v-model="block.showLabel"
                  ></b-form-checkbox></span>
      </label>
      <input type="text" v-if="block.showLabel" placeholder="Label text" v-model="block.VerifyOTPText" class="w-100 editor-input-control" id="">
         </div>
      <hr>
      <div class="px-3">
        <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync="block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model="block.style.textAlign" ></TextAlign>
      </div>
<hr>
<div class="px-3">
  <SelectColor :label="'Text Color:'" v-model="block.style.color" :opacity.sync="block.style.textOpacity"></SelectColor>
</div>
<hr>

        </b-collapse>
          </div>
          <div class="otp-action-section">
        <div v-b-toggle.otp-action-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">OTP Action Button Style</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="otp-action-collapse" class="mt-2">
         <div class="">
          <div class="px-3">
      
      <label for="button-text" class="editor-label">Button text:</label>
      <input type="text" v-model="block.verifyAuthText" placeholder="Button text" name="button-text" class="editor-input-control w-100">
     </div>
        <hr>
        <div class="px-3">
      <label for="button-text" class="editor-label">Button size:</label>
      <div class="d-flex gap">
    <div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
    <span class="" style="color: #B3AFB6;" >W</span>
      <input type="number" v-model="block.style.sendBtnWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
    </div>
    <div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
    <span class="" style="color: #B3AFB6;">H</span>
      <input type="number"  v-model="block.style.sendBtnHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
    </div>
      </div>
     </div>
     <hr>
     <div class="px-3">
      <FontFamily  v-model="block.style.sendBtnFontFamily"></FontFamily>
      <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
        <div class="d-flex align-items-center" style="gap:4px">
    <span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
      <input type="number" v-model="block.style.sendBtnFontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
    </div>
        <FontStyles :textBold.sync="block.style.sendBtnFontWeight" 
      :textItalic.sync=" block.style.sendBtnFontStyle"
      :textUnderLine.sync="block.style.sendBtnTextDecoration" class=""></FontStyles>
       
      </div>

 
    <label  for="button-text" class="editor-label">Button Position:</label>
        <div class="d-flex text-align-section">
          <label title="Start" for="left" @click="block.style.sendBtnPosition='left'" :class="block.style.sendBtnPosition=='left'?'active-editor-align':''" class="editor-text-left-control">
            <svg
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                      fill="currentColor"
                    />
                  </svg>
              </label>
              <label title="Center" for="center"  @click="block.style.sendBtnPosition='center'" :class="block.style.sendBtnPosition=='center'?'active-editor-align':''"  class="editor-text-center-control">
                <svg
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                      fill="currentColor"
                    />
                  </svg>
              </label>
              <label for="end" title="End" @click="block.style.sendBtnPosition='end'" :class="block.style.sendBtnPosition=='end'?'active-editor-align':''" class="editor-text-right-control">
                <svg
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                      fill="#6D6B6D"
                    />
                  </svg>
              </label>
           
        </div>
     </div>
     <hr>
     <div class="px-3">
      <SelectColor v-model="block.style.sendBtnBGColor" :isOpacity="false" :label="'Button Color'"></SelectColor>
  <SelectColor v-model="block.style.sendBtnColor" :isOpacity="false"  class="mt-3" :label="'Text Color'"></SelectColor>
     </div>
     <hr>
     <div class="px-3">
      <Stroke class="w-25" :isBorder="false"
      :borderRadius.sync="block.style.sendBtnBorderRadius"  ></Stroke>

     </div>
         </div>

        </b-collapse>
          </div>
          <div class="otp-action-section">
        <div v-b-toggle.otp-template-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">OTP Action Button Style</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="otp-template-collapse" class="mt-2">
          <div class="px-3">
            <div class="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Use Custom OTP Email Template:</span>
        <b-form-checkbox
        :id="`Use-Custom-Email-${Date.now()}`"
           v-model="useCustomEmailTemplate"
                  ></b-form-checkbox>
      </label>
    </div>

          </div>
          <hr>
          <div class="" v-if="useCustomEmailTemplate">
          <div class="px-3" >
            <div class="">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Email Subject:</span>
        
      </label>
      <input type="text"  placeholder="Otp Email Subject" v-model="subjectInput" class="w-100 editor-input-control" id="">
         </div>
         <div class="mt-3">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Email Preview Text:</span>
        
      </label>
      <input type="text" placeholder="Otp Email Preview Text" v-model="previewTextInput" class="w-100 editor-input-control" id="">
         </div>
        </div>
      <hr>
        <div class="px-3">
          <div   class="">
            <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">Select Sender Email:</label>
            <div class="select-per-page-count">
              <select class="w-100" name="" v-if="senders.length > 0"  v-model="selectedSenderEmail" id="">
                <option value="" disabled>Select a Sender</option>
                        <option
                          :value="sender.id"
                          v-for="sender in senders"
                          :key="sender.id"
                        >
                          {{ sender.from_email }}
                        </option>
              </select>
              <select name="" v-else disabled  id="">
                <option selected value="">No Sender Email Found</option>
                       
              </select>
            </div>
            <div class="mt-2"  v-if="!templates.length > 0">
              <Button class="w-100" @click="routeToPage(`Email`)">
                Create Email Template
              </Button>
            </div>
          </div>
          <div class="">
            <label for="" class="editorStyleTitle mt-3 d-flex align-items-center justify-content-between">Select Email Template:</label>
            <div class="select-per-page-count">
              <select  class="w-100"  v-if="templates.length > 0"  name=""  v-model="selectedEmailTemplate" id="">
                <option value="" disabled>Select an Email Template</option>
                        <option
                          :value="template.id"
                          v-for="template in templates"
                          :key="template.id"
                        >
                          {{ template.name }}
                        </option>
              </select>
              <select name="" v-else  disabled id="">
                <option selected value=""> No Email Template found</option>
                       
              </select>
            </div>
            <div class="mt-2"  v-if="!senders.length > 0">
              <Button class="w-100" @click="routeToPage(`SenderEmailVerification`)">
                Create Sender Email
              </Button>
            </div>
          </div>
        </div>
        <div class="px-3">
          <Button class="mt-3 w-100" :isLoading="saveDataLoading"  :disabled="!isSaveValid"
          @click="saveCustomEmailData">Save</Button>
        </div>
      </div>
        </b-collapse> 
          </div>
          <div class="px-3 mt-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing: (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>

</div>
</template>
<script>
import axios from "axios";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Stroke from "../MiniComponents/Stroke.vue";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";
import Button from "../../../../Dashboard/Layout/Button.vue";
import Padding from "../MiniComponents/Padding.vue";

export default {
  components:{
    SelectColor,
    Stroke,
    FontFamily,
    FontStyles,
    TextAlign,
    Button,
    Padding
  },
  props: {
    block: Object,
    isDesktopView: Boolean,
  },
  data() {
    return {
      showCustomEmailTemplateLoading: false,
      isPreOtpDataLoading: false,
      senders: [],
      templates: [],
      useCustomEmailTemplate: false,
      selectedSenderEmail: null,
      selectedEmailTemplate: null,
      subjectInput: "",
      previewTextInput: "",
      saveDataLoading: false,
    };
  },

  methods: {
    routeToPage(routeName) {
      this.$router.push({ name: `${routeName}` });
    },
    async showCustomEmailTemplates() {
      try {
        this.showCustomEmailTemplateLoading = true;
        let data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
        };
        const response = await axios.get(`/quiz/otp-email-template`, {
          params: data,
        });
        if (response.status == 200) {
          let { subject, preview_text, template_id, sender_id } =
            response.data?.data;
          this.useCustomEmailTemplate = true;
          this.selectedSenderEmail = sender_id;
          this.selectedEmailTemplate = template_id;
          this.subjectInput = subject;
          this.previewTextInput = preview_text;
        }
      } catch (error) {
        if (error) {
          throw error;
        }
      } finally {
        this.showCustomEmailTemplateLoading = false;
      }
    },
    async getPreOtpData() {
      try {
        this.isPreOtpDataLoading = true;
        const response = await axios.get(`/quiz/otp-email-template/create`);
        if (response.status == 200) {
          this.senders = response.data.data.senders;
          this.templates = response.data.data.templates;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error OCcured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isPreOtpDataLoading = false;
      }
    },
    async saveCustomEmailData() {
      try {
        this.saveDataLoading = true;
        let data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
          sender_id: this.selectedSenderEmail,
          template_id: this.selectedEmailTemplate,
          subject: this.subjectInput,
          preview_text: this.previewTextInput,
        };
        const response = await axios.post(`/quiz/otp-email-template`, data);
        if (response.status == 200) {
          this.$toasted.show("Custom Otp Email Data saved successfully!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error OCcured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.saveDataLoading = false;
      }
    },
  },
  computed: {

    isSaveValid() {
      return (
        this.selectedSenderEmail &&
        this.selectedEmailTemplate &&
        this.subjectInput !== "" &&
        this.previewTextInput !== ""
      );
    },
    textFontFamily(){
      return this.block.style.fontFamily
    },
    sendBtnFontFamily(){
      return this.block.style.sendBtnFontFamily
    },
  },
  watch:{
    textFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    sendBtnFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
  },
  mounted() {
    this.showCustomEmailTemplates();
    this.getPreOtpData();
  },
};
</script>
<style>
.headingText strong {
  font-size: 14px;
  font-weight: 500 !important;
}
.reset-option {
  border: 0.3px solid #6d6b6d;
  box-sizing: border-box;
  border-radius: 4px;
}
.subheading input {
  border-color: #b3afb6;
  font-size: 14px;
}
.subheading {
  font-weight: 400 !important;
}
.text-setting select {
  font-size: 14px;
  padding: 0;
}
.ql-container.ql-snow {
  background: white;
}
.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

/* Button Group CSS Style */

.productViewSelected {
  background: #ffa201 !important;
  color: #ffffff !important;
}
</style>
